<template>
  <div>
    <form-title :text="$t('title.select_year')" />
    <form-content>
      <div class="flex flex-wrap -mx-1 mb-6">
        <div
          class="px-1 mb-2 w-4/12 sm:w-2/12 lg:w-3/12"
          v-for="item in years"
          :key="item"
        >
          <btn-choose
            type="button"
            @clicked="$emit('update-user-data', { birthYear: item })"
            v-text="item"
          />
        </div>
      </div>
    </form-content>
  </div>
</template>

<script>
import BtnChoose from "../Ui/BtnChoose.vue";
import FormContent from "./FormContent.vue";
import FormTitle from "./FormTitle.vue";
export default {
  components: { FormTitle, BtnChoose, FormContent },
  name: "SelectYear",

  methods: {
    populateYearArray(decade) {
      return Array(10)
        .fill()
        .map((_, index) => decade + index);
    },
  },
  computed: {
    years() {
      return this.populateYearArray(this.$store.state.userData.birthDecade);
    },
  },
};
</script>
