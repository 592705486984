<template>
  <div class="mb-6 sm:mb-10 ">
    <div class="hidden lg:flex items-center absolute" v-if="showBackButton">
      <back-button :completedStep="completedStep" />
    </div>
    <transition
      appear
      appear-active-class="appear-from"
      appear-to-class="appear-to"
    >
      <h2
        class="text-2xl sm:text-3xl font-headings text-center lg:px-10"
        v-html="text"
      />
    </transition>
  </div>
</template>

<script>
import BackButton from "../BackButton.vue";
export default {
  name: "FormTitle",
  components: { BackButton },
  props: {
    text: {
      type: String,
      required: true,
      default: "",
    },
  },
  computed: {
    completedStep() {
      return this.$store.state.completedStep;
    },
    showBackButton() {
      return (
        this.$route.meta.currentStep <= 6 && this.$route.meta.currentStep > 1
      );
    },
  },
};
</script>
