<template>
  <transition
    appear
    appear-active-class="appear-from-delay"
    appear-to-class="appear-to"
  >
    <slot />
  </transition>
</template>

<script>
export default {
  name: "FormContent",
};
</script>
