<template>
  <component
    :is="type"
    @click="clicked"
    class="flex items-center justify-center rounded border bg-white border-gray-300 text-xl  hover:border-red-500 hover:bg-red-500  focus:border-red-700 focus:bg-red-700 hover:text-white h-16 w-full font-headings transition-colors "
  >
    <slot />
  </component>
</template>

<script>
export default {
  name: "BtnChoose",
  props: {
    href: {
      type: String,
      default: null,
    },
  },
  computed: {
    type() {
      if (this.href) {
        return "a";
      } else {
        return "button";
      }
    },
  },
  methods: {
    clicked() {
      this.$emit("clicked");
    },
  },
};
</script>
